import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useCallback, useState } from 'react';
import { defaultHeaders, superUserGoogleTokenURL } from './constants';

interface GoogleTokenResponse {
  idToken: string;
  accessToken: string;
  refreshToken?: string;
  expiresIn: number;
  expires: Date;
}

type GoogleResponse = Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>;

export const useSuperUserGoogleLogin = (
  onSuccessCb?: (resp: GoogleTokenResponse) => void,
  onErrorCb?: (err: any) => void
) => {
  const [running, setRunning] = useState(false);
  const onSuccess = useCallback(
    async (response: GoogleResponse) => {
      setRunning(true);
      try {
        const resp = await fetch(superUserGoogleTokenURL, {
          body: JSON.stringify({ code: response.code }),
          method: 'POST',
          headers: defaultHeaders,
        });

        if (resp.ok) {
          const data: GoogleTokenResponse = await resp.json();
          onSuccessCb?.(data);
        } else {
          onErrorCb?.(resp);
        }
      } catch (error) {
        onErrorCb?.(error);
      } finally {
        setRunning(false);
      }
    },
    [setRunning, onSuccessCb, onErrorCb]
  );

  const onError = useCallback(
    (err: any) => {
      setRunning(false);
      onErrorCb?.(err);
    },
    [onErrorCb, setRunning]
  );

  const login = useGoogleLogin({
    flow: 'auth-code',
    include_granted_scopes: false,
    hosted_domain: 'yellowdogsoftware.com',
    redirect_uri: 'postmessage',
    onSuccess,
    onError,
  });

  return [login, running] as [typeof login, boolean];
};
