import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import logo from "./logo.svg";
import logo from './logo.png';
import logoWhite from './logo-white.png';
import './App.scss';
import {
  Button,
  Tile,
  Grid,
  Row,
  Column,
  TextInput,
  Form,
  TextInputProps,
  Content,
  Modal,
} from 'carbon-components-react';

import { useForm, SubmitHandler, FormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { get } from 'lodash';
import { useDingoLogin } from './dingo';
import {
  LoginType,
  useThemed,
  postSuccessfulLoginMessage,
  useSuperUserGoogleLogin,
} from './utilities';
import { Copyright, Version } from './components';

interface Inputs {
  clientId: string;
  username: string;
  password: string;
}

const schema = yup
  .object({
    clientId: yup.string().required(),
    username: yup.string().min(2).required(),
    password: yup.string().min(2).required(),
  })
  .required();

const resolver = yupResolver(schema);

const useFormInputInvalidProps = <T extends any>(
  { errors, isValid, isDirty }: FormState<T>,
  key: keyof T
): Pick<TextInputProps, 'invalid' | 'invalidText'> => {
  return useMemo(() => {
    const invalidText = get(errors, `${key}.message`);
    return { invalid: isDirty && !!invalidText, invalidText };
  }, [errors, isValid, isDirty]);
};

function App() {
  const { register, handleSubmit, formState, setValue } = useForm<Inputs>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver,
  });

  const [login, { loading, data, error }] = useDingoLogin();

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    (variables) => {
      login({ variables });
    },
    [login]
  );

  const currentLogo = useThemed({ light: logo, dark: logoWhite });

  useEffect(() => {
    const x = new URLSearchParams(window.location.search);
    const clientId = x.get('client_id');
    setValue('clientId', clientId ?? '');
  }, [setValue]);

  useEffect(() => {
    const result = data?.login?.result;
    if (result) {
      postSuccessfulLoginMessage(result, LoginType.USER);
    }
  }, [data]);

  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);
  useEffect(() => {
    if (error) {
      setShowModal(true);
    }
  }, [error, setShowModal]);

  const clientIdInvalidProps = useFormInputInvalidProps<Inputs>(formState, 'clientId');
  const usernameInvalidProps = useFormInputInvalidProps<Inputs>(formState, 'username');
  const passwordInvalidProps = useFormInputInvalidProps<Inputs>(formState, 'password');

  const [loginSU, suLoading] = useSuperUserGoogleLogin(
    (data) => postSuccessfulLoginMessage(data, LoginType.SUPERUSER_GOOGLE),
    () => setShowModal(true)
  );

  return (
    <Content>
      <Modal
        modalHeading="Unable to Login"
        passiveModal
        open={showModal}
        color="red"
        onRequestClose={closeModal}
      >
        <p>{error?.message}</p>
      </Modal>
      <Grid className="container">
        <Row className="body">
          <Column sm={0} md={1} lg={4} />
          <Column>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Tile className="margin-10-padding-10">
                <div className="app-logo-container">
                  <img src={currentLogo} className="App-logo" alt="logo" />
                </div>
                <h1 className="centered">Log In</h1>
                <TextInput
                  id="clientId"
                  labelText="Client ID"
                  size="sm"
                  {...register('clientId')}
                  {...clientIdInvalidProps}
                />
                <TextInput
                  id="username"
                  labelText="Username"
                  size="sm"
                  {...register('username')}
                  {...usernameInvalidProps}
                />
                <TextInput
                  id="Password"
                  labelText="Password"
                  size="sm"
                  type="password"
                  {...register('password')}
                  {...passwordInvalidProps}
                />
                <Button
                  size="sm"
                  kind="primary"
                  type="submit"
                  disabled={!formState.isValid || loading || suLoading}
                  isExpressive
                >
                  Submit
                </Button>
                <p className="errorMessage">{error?.message}</p>
              </Tile>
            </Form>
          </Column>
          <Column sm={0} md={1} lg={4} />
        </Row>
        <Row className="spacer-row" />
      </Grid>
      <div className="footer">
        <Grid>
          <Row>
            <Column sm={0} md={1} lg={4} />
            <Column>
              <div className="centered">
                <Version />
              </div>
            </Column>
            <Column>
              <div className="centered">
                <Copyright />
              </div>
            </Column>
            <Column>
              <div className="centered">
                <Button kind="ghost" size="sm" onClick={() => loginSU()}>
                  Support Login
                </Button>
              </div>
            </Column>
            <Column sm={0} md={1} lg={4} />
          </Row>
        </Grid>
      </div>
    </Content>
  );
}

export default App;
