export const googleAuthClientId = Object.freeze(
  process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ??
    '563937062236-4qe82g7b8r3koita60vk85a0fp52ufnq.apps.googleusercontent.com'
);

export const superUserGoogleTokenURL = Object.freeze(
  process.env.REACT_APP_SUPERUSER_GOOGLE_TOKEN_URL ??
    'https://auth.yellowdogsoftware.com/token/google-superuser'
);

export const defaultHeaders = Object.freeze({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
});
