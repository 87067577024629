import { LoginType } from './enums';

export const postSuccessfulLoginMessage = (
  authResponse: any,
  loginType: LoginType = LoginType.USER
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const target = searchParams.get('target');
  if (target) {
    const message = {
      ...authResponse,
      target,
      loginType,
    };
    // @ts-ignore
    // react native webview support
    const reactNativePostMessage = window?.ReactNativeWebView?.postMessage as
      | ((msg: string) => void)
      | undefined;
    if (reactNativePostMessage) {
      reactNativePostMessage(JSON.stringify(message));
    } else if (window?.opener?.postMessage) {
      window.opener.postMessage(message, target);
      window.close();
    } else if (window?.parent?.postMessage) {
      window?.parent?.postMessage(message, target);
    }
  } else {
    throw new Error('');
  }
};
